// src/pages/preview.js

import * as React from "react"
import { withPreviewResolver } from "gatsby-source-prismic"
import { linkResolver } from "../utils/linkResolver"
import Layout from "../components/layout"

const PreviewPageComponent = ({ isPreview, isLoading }) => {
  //   console.log(isPreview);
  if (isPreview === false) return "Not a preview!"

  return (
    <Layout>
      <p>Loading via PreviewResolver</p>
    </Layout>
  )
}

export default withPreviewResolver(PreviewPageComponent, {
  repositoryName: "vindicia-public",
  linkResolver,
})

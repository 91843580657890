// -- The Link Resolver
// This function will be used to generate links to Prismic documents
// As your project grows, you should update this function according to your routes

module.exports.linkResolver = ({ node /*, key, value*/ }) => doc => {
  if (doc.type === "custom_template") {
    if (node && node.data && node.data.url_prefix) {
      return `/${node.data.url_prefix}/${doc.uid}/`
    }
  }
  if (doc.type === "product_page") {
    if (node && node.data && node.data.url_prefix) {
      return `/${node.data.url_prefix}/${doc.uid}/`
    } else return `/products/${doc.uid}/`
  }
  if (doc.type === "subscription_management") {
    if (node && node.data) {
      return `/${node.data.url_prefix != null ? `${node.data.url_prefix}` : ""}${
        node.data.second_url_prefix != null ? `/${node.data.second_url_prefix}/` : ""
      }${doc.uid}/`
    } else return `/${doc.uid}/`
  }
  if (doc.type === "become_partner_page") {
    return `/company/${doc.uid}/`
  }
  // Resolving single pages
  if (doc.type === "about_page" && doc.uid === "about-vindicia") {
    return `/company/${doc.uid}/`
  }
  if (doc.type === "events_pag" && doc.uid === "upcoming-events") {
    return `/company/${doc.uid}/`
  }
  if (doc.type === "our_team_page" && doc.uid === "management-team") {
    return "/company/team/"
  }
  if (doc.type === "awards_page" && doc.uid === "awards") {
    return `/company/${doc.uid}/`
  }
  if (doc.type === "glossary_page" && doc.uid === "vindicia-glossary") {
    return `/technical-center/${doc.uid}/`
  }
  if (doc.type === "client_services_page" && doc.uid === "customer-deployment-solutions") {
    return `/technical-center/${doc.uid}/`
  }
  if (doc.type === "client_categories") {
    return `/list-of-clients/${doc.uid}/`
  }
  if (doc.type === "whitepapers") {
    return `/resources/white-papers/${doc.uid}/`
  }
  if (doc.type === "infographics") {
    return `/resources/infographics/${doc.uid}/`
  }
  if (doc.type === "ebooks") {
    return `/resources/ebooks/${doc.uid}/`
  }
  if (doc.type === "reports") {
    return `/resources/analyst-reports-reviews/${doc.uid}/`
  }
  if (doc.type === "datasheets") {
    return `/resources/datasheets/${doc.uid}/`
  }
  if (doc.type === "authors") {
    return `/author/${doc.uid}/`
  }
  if (doc.type === "guides") {
    return `/resources/guides/${doc.uid}/`
  }
  if (doc.type === "blog_categories") {
    return `/blog/category/${doc.uid}/`
  }
  if (doc.type === "resource_categories") {
    return `/resources/${doc.uid}/`
  }
  if (doc.type === "job_role_category") {
    return "/company/careers/"
  }
  if (doc.type === "faq_categories") {
    return "/technical-center/faq/general-faq/"
  }
  if (doc.type === "product_page" && doc.uid === "marketone") {
    return "/marketone"
  }
  if (doc.type === "privacy_policy_page") {
    return `/${doc.uid}/`
  }
  if (doc.type === "events") {
    return `/company/events/${doc.uid}/`
  }
  if (doc.type === "press_release_entry") {
    return `/company/press-releases/${doc.uid}/`
  }
  if (doc.type === "news_entry") {
    return `/company/in-the-news/${doc.uid}/`
  }
  if (doc.type === "job_listing") {
    return `/company/careers/${doc.uid}/`
  }
  if (doc.type === "faq_pages") {
    return `/technical-center/faq/${doc.uid}/`
  }
  if (doc.type === "glossary_entry") {
    return "/technical-center/vindicia-glossary"
  }
  if (doc.type === "subscribe_plans") {
    return `/products/${doc.uid}/`
  }
  if (doc.type === "blog_entry") {
    return `/blog/${doc.uid}/`
  }
  //webinars here have a custom link resolver for standalone page
  if (doc.type === "webinars") {
    return `/webinars/${doc.uid}/`
  }
  if (doc.type === "home_page") {
    return "/"
  }
  //console.log(node,key,value,doc,"Fallback to default path");
  // Default
  return doc.uid ? `/${doc.uid}/` : "/"
}
